import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import Layout from '../components/layout';
import { Footer, HelmetEx, Hero, LogoSlider, References } from '../components';
import { STATIC_IMAGES } from '../resources/references';
import LocalizationService from '../services/localization-service';
import { ResourceUtils } from '../utils';
import ObjectUtils from '../utils/object-utils';

const SECOND_MATCHING_GROUP = 2

const createMap = (prefix, resources) => {
    const prefixEscaped = prefix?.replace(/\./g, '\\.')
    const expression = new RegExp(`(${prefixEscaped}\.)(.*)`);
    const object = {}
    resources.forEach(({ key }) => {
        const property = key.match(expression)?.[SECOND_MATCHING_GROUP]
        if (property) {
            object[property] = property;
        }
    })
    return object
}

const ServicePageTemplate = ({ data, pageContext }) => {
    const { name, pagesResourceName } = pageContext;
    const { content, downloadContent, pages } = data;
    const { resources } = content;
    const localized = new LocalizationService(resources)
    const map = useMemo(() => createMap(name, resources), [resources])
    const copy = ResourceUtils.getMicrocopy(localized, name, map)
    const structuredCopy = ObjectUtils.fromMap(copy);

    const { resources: pageResources } = pages
    const pagesMap = useMemo(() => createMap(pagesResourceName, pageResources), [pageResources])
    const localizedPages = new LocalizationService(pageResources)
    const pagesMicrocopy = ResourceUtils.getMicrocopy(localizedPages, pagesResourceName, pagesMap)
    const pagesStructured = ObjectUtils.fromMap(pagesMicrocopy)
    const servicePages = pagesStructured?.page?.map((page => ({ ...page, slug: page.path, label: page?.title })))
    const currentService = servicePages.find(service => service.slug === structuredCopy?.page?.slug);
    if (!currentService) throw new Error('Could not find current service');

    const references = structuredCopy.references?.map(reference => ({
        key: { value: reference.key },
        type: { value: reference.type }
    })) ?? [];

    return <Layout className="services">

        <HelmetEx>
            <div metadata="title">
                Polymath Services | {`${copy["hero.title_1"]} ${copy["hero.title_2"]}`}
            </div>
            <div metadata="description">{copy["hero.subtitle"]}</div>
            <div metadata="path">{copy['page.slug']}</div>
            <div metadata="canonicalurl">{copy['page.slug']}</div>
        </HelmetEx>

        <div className="navigation bg-brand-neutral-1 d-none d-md-block">
            <div className="container border-top">
                <div className="row">
                    <div className="col-4">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/services/start">{pagesStructured?.nav_label}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{currentService.label}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-8">
                        <ul class="nav justify-content-end">
                            <li class="nav-item">
                                <span className="nav-link disabled">{pagesStructured?.see_also_label}</span>
                            </li>
                            {servicePages.filter(service => service.slug !== copy['page.slug']).map(service =>
                                <li class="nav-item">
                                    <a class="nav-link" href={service.slug}>{service.label}</a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <Hero
                cta={<Link className="btn btn-brand-cta-1 btn-lg text-nowrap" to={copy["hero.cta.path"]}>{copy["hero.cta.label"]}</Link>}
                description={copy["hero.subtitle"]}
                heading1={copy["hero.title_1"]}
                heading2={copy["hero.title_2"]}
                image={STATIC_IMAGES[copy["hero.image"]]}
                options={{ showImageShadow: false }}
            />
        </div>

        <div className="bg-brand-neutral-2 py-5">
            <div className="container">
                <div className="row text-center">
                    {structuredCopy.sections[0].cards.map(card =>
                        <div className="col-12 col-md gy-4 gy-md-0">
                            {card.title}
                        </div>
                    )}
                </div>
            </div>
        </div>

        <div className="bg-brand-neutral-1 py-5">
            <div className="container">
                <div className="row text-center gy-5">
                    <div className="col-12">
                        <h2>{copy['sections[1].title']}</h2>
                    </div>
                    {structuredCopy.sections[1].cards.map(card =>
                        <div className="col-12 col-md-6 gy-4 gy-lg-0">
                            {card.title}
                        </div>
                    )}
                    <div className="col-6 col-md-4 col-lg-2 offset-3 offset-md-4 offset-lg-5 text-center text-nowrap d-flex justify-content-center">
                        <Link className="btn btn-brand-cta-1 w-100 button-max-content" to={copy["sections[1].cta.path"]}>
                            {copy["sections[1].cta.label"]}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-brand-neutral-3 py-5">
            <div className="container">
                <div className="row text-center">
                    <div className="col">
                        <h2 className="p-0 m-0">{copy["sections[2].title"]}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-brand-neutral-1 py-5">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <h2>{copy["sections[3].title"]}</h2>
                    </div>
                    <div className="col-12 col-lg-10 offset-lg-1 px-4">
                        <p className="lead p-4">
                            {copy['sections[3].body']}
                        </p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 offset-3 offset-md-4 offset-lg-5 text-center text-nowrap d-flex justify-content-center">
                        <Link className="btn btn-brand-cta-1 w-100 button-max-content" to={copy["sections[3].cta.path"]}>
                            {copy["sections[3].cta.label"]}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="slider-section bg-brand-neutral-2 py-5">
            <div className="container">
                <div className="d-none d-lg-block">
                    <LogoSlider slidesToShow={5} slidesToScroll={2} />
                </div>
                <div className="d-none d-lg-none d-md-block">
                    <LogoSlider slidesToShow={2} slidesToScroll={2} />
                </div>
                <div className="d-block d-md-none">
                    <LogoSlider slidesToShow={2} slidesToScroll={1} />
                </div>
            </div>
        </div>

        <div className="bg-brand-primary-2 py-5">
            <div className="container">
                <div className="row text-center">
                    <div className="col">
                        <h2 className="p-0 m-0">{copy["sections[5].title"]}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-brand-primary-1 py-5">
            <div className="container">
                <div className="row text-center gy-5">
                    {structuredCopy.sections[5].cards.map(card =>
                        <div className="col-12 col-md-6">
                            <strong className="d-block">{card.title}</strong>
                            <span>{card.text}</span>
                        </div>
                    )}
                    <div className="col-6 col-md-4 col-lg-2 offset-3 offset-md-4 offset-lg-5 text-center text-nowrap d-flex justify-content-center">
                        <Link className="btn btn-brand-cta-1 w-100 button-max-content" to={copy["sections[5].cta.path"]}>
                            {copy["sections[5].cta.label"]}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-brand-neutral-1 py-5">
            <div className="container">
                <div className="row text-center gy-5">
                    <div className="col-12">
                        <h2>{copy['sections[6].title']}</h2>
                    </div>
                    {structuredCopy.sections[6].cards.map(card =>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="card card-component card-standard h-100">
                                <div className="card-body h-100">
                                    <h5 className="card-title">{card.title}</h5>
                                    <div className="card-text">{card.text}</div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-6 col-md-4 col-lg-2 offset-3 offset-md-4 offset-lg-5 text-center text-nowrap d-flex justify-content-center">
                        <Link className="btn btn-brand-cta-1 w-100 button-max-content" to={copy["sections[1].cta.path"]}>
                            {copy["sections[1].cta.label"]}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-brand-primary-2 py-5">
            <div className="container">
                <div className="row text-center">
                    <div className="col">
                        <h2 className="p-0 m-0">{copy["sections[7].title"]}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-brand-primary-1 py-5">
            <div className="container">
                <div className="row text-center gy-5">
                    {structuredCopy.sections[7].cards.map(card =>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="card card-component card-standard h-100">
                                <div className="card-body">
                                    <h5 className="card-title">{card.title}</h5>
                                    <p className="card-text">{card.text}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-6 col-md-4 col-lg-2 offset-3 offset-md-4 offset-lg-5 text-center text-nowrap d-flex justify-content-center">
                        <Link className="btn btn-brand-cta-1 w-100 button-max-content" to={copy["sections[7].cta.path"]}>
                            {copy["sections[7].cta.label"]}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        {/* TODO: Add Testimonials */}

        <div className="bg-brand-neutral-1 py-5">
            <div className="container">
                <div className="row text-center gy-5">
                    <div className="col-12">
                        <h2>{copy['sections[8].title']}</h2>
                    </div>
                    <div className="col-12 col-md-10 offset-md-1">
                        <ul className="text-start">
                            {structuredCopy.sections[8].bullets.map(bullet =>
                                <li>{bullet}</li>
                            )}
                        </ul>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 offset-3 offset-md-4 offset-lg-5 text-center text-nowrap d-flex justify-content-center">
                        <Link className="btn btn-brand-cta-1 w-100 button-max-content" to={copy["sections[8].cta.path"]}>
                            {copy["sections[8].cta.label"]}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        {references?.length ? <References references={references} contentData={downloadContent} /> : <></>}

        <Footer />
    </Layout>;
};

export default ServicePageTemplate;

export const query = graphql`
  query ServicesPage($name: String!, $languageRegEx: String!, $pagesResourceName: String!) {
    content: contentfulResourceSet(name: {eq: $name}, node_locale: {regex: $languageRegEx}) {
        name
        resources {
            key
            value {
            value
            }
            isHtml
        }
        images {
            image {
                gatsbyImageData
            }
        }
        node_locale
    }
    downloadContent: contentfulResourceSet(
        name: {regex: "/^downloads\\.content.*/"}
        node_locale: {regex: $languageRegEx}
      ) {
        name
        resources {
          key
          value {
            value
          }
          isHtml
          node_locale
        }
      }
    pages: contentfulResourceSet(name: {eq: $pagesResourceName}, node_locale: {regex: $languageRegEx}) {
        name
        resources {
            key
            value {
            value
            }
            isHtml
        }
        node_locale
    }
  }
`;